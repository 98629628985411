.multiselectctr-option-box {
    width: 100%;
    border: 1px solid whitesmoke;
    padding: 5px;
    background-color:white;
    position:absolute;
    top:0;
    left:0;

}
.multiselectctr {
    width: 100%;
}
.multiselectctr-option-item {
    padding: 5px;
    width: 100%;
    cursor:pointer;
    border-bottom:1px solid whitesmoke;
}


.multiselectctr-option-item:hover{background-color:whitesmoke;}
