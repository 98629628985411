.fg-fm-file-item{border:2px solid rgba(0,0,0,.1);text-align:center;padding:10px;
                 border-radius:6px;margin-bottom:20px;margin-right:10px;cursor:pointer;max-height:225px;}

    .fg-fm-file-item:hover {
        border: 2px solid #5E95DE;
    }

    .fg-fm-file-item-img-box {
        pointer-events: none;
    }
.fg-fm-file-item img {
    max-width: 150px;
    max-height: 150px;
    margin-bottom: 5px;
    pointer-events: none;
}

.fg-fm-file-item i {
    font-size:40px;
    margin-bottom: 5px;
    pointer-events: none;
}

    .fg-fm-file-item-text {
        pointer-events:none;
        word-wrap: break-word;
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
        line-height:120%;
    }
