.fgd-app-header {
    display: grid;
    grid-template-columns: 239px 1fr;
    grid-gap: 0px;
    background-color: #5E95DE;
    padding:10px 5px 0px 0px;
    position:fixed;
    width:100%;
    z-index:2;
}

.fgd-brand-icon-only {
    color: white;
    font-size: 22px;
    text-align: center;
    margin: 0px;
    padding: 0px;
    line-height:90%;
}

.fgd-brand-nameonly {
    color: white;
    font-size: 28px;
    font-weight: bold;
    line-height: 90%;
}

.fgd-app-mainnav-toggle-box {
    text-align: center;
    cursor:pointer;
}

.fgd-app-mainnav-toggle-btn{
    font-size:24px;font-weight:bold;color:white;
}

.fgd-app-header .fgd-app-header-left {
    display: grid;
    grid-template-columns: 50px 1fr;
}

.fgd-app-header .fgd-app-header-right {
    display: grid;
    grid-template-columns: 50px 1fr 1fr;
}

.fgd-app-links-area {
    text-align: right;
}


@media screen and (min-width:0px) {
    /* styles for browsers larger than 0px; */

    .fgd-app-container .fgd-app-header {
        grid-template-columns: 50px 1fr;
    }

    .fgd-app-container .fgd-brand-nameonly {
        display: none;
    }

    .fgd-app-container .fgd-app-header .fgd-app-header-left {
        display: inline-block;
        width: 50px;
    }

}

@media screen and (min-width:768px) {
    /* styles for browsers larger than 768px; */
    .fgd-app-container .fgd-app-header {
        grid-template-columns: 50px 1fr;
    }

    .fgd-app-container .fgd-brand-nameonly {
        display: none;
    }

    .fgd-app-container .fgd-app-header .fgd-app-header-left {
        display: inline-block;
        width: 50px;
    }
}

@media screen and (min-width:1170px) {
    /* styles for browsers larger than 1170px; */

    .fgd-app-container .fgd-app-header {
        grid-template-columns: 239px 1fr;
    }

    .fgd-app-container .fgd-brand-nameonly {
        display: block !important;
    }

    .fgd-app-container .fgd-app-header .fgd-app-header-left {
        display: grid;
        width: auto;
    }
}