.fg-app-page-header-box {
    display: grid;
    grid-template-columns: 1fr 400px;
    background-color: rgb(255,255,255);
    padding:10px 10px 5px 10px;
}

.fg-app-page-title {
    font-size: 20px;
    margin: 0px 0px 5px 0px;
    font-weight: 500;
    line-height: 90%;
    color: #458EE1;
}
fg-app-page-title-box {

}

.fg-app-page-btn{
    margin-left:10px;
}

.fg-app-page-btn-box {
    text-align: right;
}
.fg-btn-new i{
    margin-right:5px;
}

.fg-breadcrumbs {color:gray;font-size:10pt;margin:0px;padding:0px;}

    .fg-breadcrumbs a {
        cursor: pointer;
        color: #458EE1 !important; text-decoration:none;
    }

    .fg-btn-new{color:white;}
.fg-btn-dropdown-new{border-radius:4px;}

.fg-save-dropdown {
    background-color: #198754;
    border-radius:4px;
}
.fg-save-dropdown-btn {color:white;}