.editor pre {
    background-color: #EEEEEE !important;
    color: black !important;
    padding: 10px;
    border-radius: 7px;
}

.tox .tox-editor-header{z-index:1 !important;}


.fg-machat-post{border-bottom:1px solid #c0c0c0;padding:20px 20px 20px 20px;}
.fg-machat-user-post{background-color:white;}
.fg-machat-ma-post {
    background-color: #F7F7F8;
}