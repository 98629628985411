.app-menu {
    color: #607d8b;
    padding:0px 0px 10px 0px;
}

    .app-menu span {
        color: #607d8b;
    }
    .app-menu a{
        text-decoration:none;
    }

.app-menu ul{
    list-style:none;
    padding:0px;
    margin:0px;
}



.app-menu li {
    cursor: default;
    padding: 0px 0px 0px 0px;
}

.icon-text-container{padding:9px 0px 9px 0px;}

.app-menu .nav-link-item:hover .icon-text-container .nav-link-icon {
    color: #26C6DA !important;
}

    .app-menu .nav-link-item:hover .icon-text-container span {
        color: #26C6DA !important;
    }

    .app-menu .nav-link-item:hover .icon-text-container {
        background-color: whitesmoke !important;
    }


.nav-link-item-active .icon-text-container .nav-link-icon {
    font-weight: bold;
    color: #26C6DA !important;
}
.nav-link-item-active .icon-text-container span {
    font-weight: bold;
    color: #26C6DA !important;
}

.nav-link-item-active .icon-text-container {
    background-color: whitesmoke;
}

.nav-link-child .nav-link-item-active .icon-text-container .nav-link-icon {
    font-weight: bold;
    color: #000000 !important;
}

.nav-link-child .nav-link-item-active .icon-text-container span {
    font-weight: bold;
    color: #000000 !important;
}

.nav-link-child .nav-link-item-active .icon-text-container {
    background-color: #ffffff;
}

.dropdown-item {
    cursor: default;
}

.app-menu-level-2 {
    padding-left: 8px !important;
}

.app-menu .nav-link-icon {
    width: 45px;
    text-align: center;
    color: #607d8b;
    font-size:22pt;
}

.fgd-app-mainnav {
    width: 239px;
    display: block !important;
    background-color: white;
    border-right: 1px solid whitesmoke;
    position: fixed;
    z-index: 1;
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}

.app-menu-level-2-inactive{
    display: none;
}

.icon-text-container {
}
    .icon-text-container .nav-link-icon {
        vertical-align: middle;
        font-size: 20px;
    }

    .icon-text-container span {
        vertical-align:middle;
    }

@media screen and (min-width:0px) {
    /* styles for browsers larger than 0px; */

    .short-app-menu .fgd-app-mainnav {
        display: none !important;
    }

    .app-menu li a i {
        display: inline-block;
        width: 45px;
        text-align: center;
    }

    .short-app-menu .app-menu li a span {
        display: none;
    }

    .full-app-menu .fgd-app-mainmenu {
        width: 239px;
        display: block !important;
    }

    .full-app-menu .app-menu li a span {
        display: inline-block;
    }

}

@media screen and (min-width:768px) {
    /* styles for browsers larger than 768px; */


    .short-app-menu .fgd-app-mainnav {
        display: none !important;
    }

    .app-menu li a i {
        display: inline-block;
        width: 45px;
        text-align: center;
    }

    .short-app-menu .app-menu li a span {
        display: none;
    }

    .full-app-menu .fgd-app-mainnav {
        width: 239px;
        display: block !important;
    }

    .full-app-menu .app-menu li a span {
        display: inline-block;
    }

    .full-app-menu .fgd-app-page-area {
        padding-left: 239px;
        display: block;
    }
}

@media screen and (min-width:1170px) {
    /* styles for browsers larger than 1170px; */

    .short-app-menu .fgd-app-mainnav {
        display: none !important;
    }

    .app-menu li a i {
        display: inline-block;
        width: 45px;
        text-align: center;
    }

    .short-app-menu .app-menu li a span {
        display: none;
    }

    .full-app-menu .fgd-app-mainnav {
        width: 239px;
        display: block !important;
    }

    .full-app-menu .app-menu li a span {
        display: inline-block;
    }

    .full-app-menu .fgd-app-page-area {
        padding-left: 239px;
        display: block;
    }

}