.fgkb-board-column-list{height:600px;border:1px solid white;overflow:auto;background-color:white;padding:5px;}

.fgkb-board-card {
    border-top: 1px solid #EAEAEA;
    border-right: 1px solid #EAEAEA;
    border-bottom: 1px solid #EAEAEA;
    border-left: 5px solid #EAEAEA;
    background-color: #ffffff;
    padding: 5px;
    font-size: 14px;
    box-shadow: 2px 1px 3px 0 rgba(0,0,0,0.1);
    border-radius: 3px;
    margin-bottom:10px;
}
.fgkb-board-card-header{display:grid;grid-template-columns:1fr 25px;margin-bottom:5px;}
.fgkb-board-card-line-two {
    display: grid;
    grid-template-columns: 80px 1fr 25px;
}

.fgkb-board-card-line-three {
    font-size: 10px;
    color: #C0C0C0;
    line-height:115%;
}