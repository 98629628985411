
.pb-widget {
    cursor: default;
}



.fg-pg-panel-section-title {
    text-align: left;
    padding-top: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #808080;
}

.fg-pg-panel-sub-section-title {
    font-size: 12px;
    padding-bottom: 5px;
}

.fg-pg-panel-sub-section {
    padding: 5px;
    background-color: #808080;
    color: #ffffff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
}

.fg-pb-widget-list {
    padding: 20px 0px 0px 0px;
}

.fg-pg-widget-list-item {
    background-color: white;
    color: #C0C0C0;
}

.fg-pg-widget-list-item-icon {
    background-color: white;
    text-align: center;
}

.fg-pg-widget-list-item-text {
    background-color: white;
    text-align: center;
}

.fg-pg-template-list-item-text {
    background-color: white;
    text-align: left;
}

.fg-pb-target-placeholder {
    border-top: 2px solid red;
    pointer-events: none;
    background-color: red;
    font-family: Arial;
    color: whitesmoke;
    width: 100%;
    padding: 0px;
    border-radius: 15px;
    font-size: 9px;
}

.fg-pg-empty-container {
    color: grey;
    text-align: center;
    padding-top: 10px;
    font-size: 10px;
    pointer-events: none;
}

.fg-pb-context-menu-item {
    display: grid;
    grid-template-columns: 20px 1fr;
    grid-gap: 10px;
    cursor: pointer;
    padding: 4px 8px 4px 8px;
}

    .fg-pb-context-menu-item:hover {
        background-color: #5E95DE;
        color: white;
    }


.fg-pb-property-sub-label {
    font-size: 12px !important;
    padding-top: 4px;
}

.fg-pb-property-field {
    padding: 2px;
    min-height: 18px !important;
}

.pb-noclick {
    pointer-events: none;
}

.pb-no-click-children * {
    pointer-events: none;
}

.fg-pb-subsection-instruction {
    font-size: 14px;
    margin-bottom: 5px;
}


.fg-pb-hl-labels-box .fg-pb-hl-ext-label-item {
    display: none;
}

.fg-pb-hl-labels-box .fg-pb-hl-ext-label-item-show {
    display: block;
}

.fg-pb-page-item {
    color: #808080;
    cursor: pointer;
}

.fg-pb-panel-tab {
    padding: 3px 7px 3px 7px;
    background-color: white;
    border-top: 5px solid white;
}

.fg-pb-panel-tab-active {
    padding: 3px 7px 3px 7px;
    background-color: white;
    border-top: 5px solid #5E95DE;
}

.fg-element-nav-item {
    background-color: transparent;
    color:black;
    cursor:default;
}

.fg-element-nav-item:hover{background-color:#e2e2e2;}

.fg-element-nav-item:active {
    background-color: #5E95DE;
    color: white;
}

.fg-element-nav-item-active {
    background-color: #5E95DE !important;color:white !important;
}

.fgao-slideout-tab-text {
    font-size: 14px;
    width: 200px;
    transform-origin: left top;
    position: relative;
    left: 22px;
    top: 10px;
    color: #ffffff;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    text-transform: uppercase;
}