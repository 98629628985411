.fgau-connector-yes {
    stroke: #A7ABB7 !important;
    cursor: pointer;
}

.fgau-connector-no {
    stroke: #FF0000 !important;
    cursor: pointer;
}

.fgau-connector-active {
    stroke: #A7ABB7 !important;
}

.fgao-auto-item {
    z-index: 1;position: absolute;width: 100px; background-color: #ffffff; padding: 10px; text-align: center; border: 1px solid #E9EAED; border-radius: 6px;
}

.fgao-auto-item-noback {
    background-color: transparent;
    border: none;
}

.fgao-auto-item-icon {
    pointer-events: none;
}

.fgao-auto-item-text {
    pointer-events: none;
}

.fgao-slideout-tab-text {
    font-size: 14px;
    width: 200px;
    transform-origin: left top;
    position: relative;
    left: 22px;
    top: 10px;
    color: #ffffff;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    text-transform: uppercase;
}

.fgao-panel-section-item {
    background-color: white;
    color: grey;
    border-radius: 4px;
    cursor: move;
    border: 1px solid #c0c0c0;
    text-align: center;
    padding: 10px;
}

.fgao-panel-section-item-icon {
    font-size: 40pt;
    color: grey;
    padding: 0px;
    margin: 0px;
    height: 50px;
    text-align: center;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fgao-panel-section-item-text {
    font-size: 11pt;
    color: #808080;
    margin-top: 7px;
    line-height: 125%;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


.fgao-auto-item-selected {
    border: 1px solid #5E95DE;
}

.fgab-forecast-summary{padding:10px;}
.fgab-forecast-summary-section-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    color:rgb(118, 131, 135);
}
.fgab-forecast-metric-box{background-color:#ffffff;padding:5px;border-radius:7px;}
.fgab-forecast-metric-label{font-size:10px;text-align:right;color:#808080;}
.fgab-forecast-metric-value {
    font-size: 20px;
    text-align: right;
    font-weight: 500;
    color: #368BE3;
}

.fgab-forecast-expense-box {
    background-color: #ffffff;
    border: 2px solid #FC4B6C;
    padding: 10px;
    border-radius: 7px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.fgab-forecast-expense-section-title {
    color: #FC4B6C;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
}
.fgab-forecast-expense-label {
    color: #FC4B6C;
}
.fgab-forecast-expense-value {
    color: #FC4B6C;
    text-align: right;
}


.fgab-forecast-expense-total-label {
    font-size: 18px;
    font-weight: 500;
    color: #FC4B6C;
}
.fgab-forecast-expense-total-value {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    color: #FC4B6C;
}


.fgab-forecast-profit-box {
    background-color: #26C6DA;
    padding: 10px;
    border-radius: 7px;
    margin-bottom: 0px;
}

.fgab-forecast-profit-label{font-size:14px;color:#ffffff;}
.fgab-forecast-profit-value {
    font-size: 18px;
    font-weight:500;
    color: #ffffff;
}

.fg-pb-panel-tab {
    padding: 3px 7px 3px 7px;
    background-color: white;
    border-top: 5px solid white;
}

.fg-pb-panel-tab-active {
    padding: 3px 7px 3px 7px;
    background-color: white;
    border-top: 5px solid #5E95DE;
}