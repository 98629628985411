.cal{max-width:240px;user-select:none;}
.cal-nav-box{display:grid;grid-template-columns:40px 1fr 40px;padding:3px;color:#353935;}
.cal-monthyear{text-align:center;}
.cal-dow-box {display:grid;grid-template-columns:repeat(7,1fr);padding:5px;grid-gap:5px;color:#353935;}
.cal-dom-box {display:grid;grid-template-columns:repeat(7,1fr);padding:5px;grid-gap:5px;}
.cal-dow{text-align:center;text-transform:uppercase;font-size:11px;font-weight:bold;}
.cal-dom{height:25px;text-align:center;font-size:14px;padding-top:1px;border-radius:50%;}

.cal-nav{cursor:pointer;}
.prev{text-align:left;}
.next{text-align:right;}

.cal .today{background-color:#D9DADF;color:#353935;}
.cal .avail{background-color:#AFE1AF;color:#353935;cursor:pointer;}
.cal .footer{font-size:10px;padding:0px 10px 10px 10px;}
.cal .legend {background-color:#AFE1AF;width:15px;height:15px;display:inline-block;margin-right:10px;}