/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.noclick {
    pointer-events: none;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f0f0f0;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(192, 192, 192,.5);
}

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgba(192, 192, 192,1);
    }

.fg-app-page-box {
    margin: 16px 10px 16px 10px;
    padding: 16px 16px 16px 16px;
    border-top: 1px solid #f0f0f0;
    background-color: #ffffff;
}

.fg-helper-text {
    font-size: 14px;
    font-style:italic;
    color:grey;
}
.css-1wc848c-MuiFormHelperText-root {
    margin-left:0px!important;
}

.row {
    margin-bottom: 20px;
}

.segment .row {
    margin-bottom: 0px !important;    
}
.segment .segment-title{font-size:20px;font-weight:bold;margin-bottom:20px;color:black;}


.fgd-app-container {
    background-color: #F5F5F7;
}

.fg-page-error-box{background-color:red;color:white;padding:3px;border-radius:4px;}

.fg-number-no-arrows::-webkit-inner-spin-button,
.fg-number-no-arrows::-webkit-outer-spin-button 
{
    -webkit-appearance: none;
    margin: 0;
}


